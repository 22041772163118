.btn {
  @include pxRem(padding, 5 10);
  @include pxRem(border-radius, 4);
  @include pxRem(font-size, 14);
  @include pxRem(height, 40);
  background: none;
  color: map-get($colors, black);
  line-height: 1;
  cursor: pointer;
  transition: background 400ms ease;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: map-get($colors, primary-dark);
  }
  &:focus {
    outline: none;
  }
  &.disabled,
  &:disabled {
    pointer-events: none;
    color: map-get($colors, txt-disabled);
    border-color: map-get($colors, txt-disabled);
    background: map-get($colors, bg-gray-light);
  }
}
.btn-app {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180.976px;
  height: 53px;
  background-color: unset;
  background-size: contain;
  background-image: url(../../images/btn-open-app.svg);
  background-repeat: no-repeat;
  color: #fff;
  font-family: Goldman;
  font-size: 16px;
  font-weight: 700;
}
