// === Font === //
$font-body: "Poppins", sans-serif;

// === Font Weight === //
$font-weight: (
  normal: 400,
  medium: 600,
  bold: 700,
);

// == Breakpoints === //
$breakpoints: (
  small: 480px,
  medium: 768px,
  wide: 960px,
  large: 1024px,
  largest: 1280px,
);

// === Colors === //
$colors: (
  primary: #333,
  white: #fff,
  black: #111,
  txt-black: #333,
  txt-gray-dark: #777,
  txt-gray: #939394,
  border-black: #333,
  border-gray: #e4e4e4,
  border-gray-light: #f1f1f1,
  bg-gray: #e9eaec,
  bg-gray-light: #f7f7f7,
  brand-html: #f2652a,
  brand-css: #379ad7,
  brand-js: #f1da4e,
  brand-scss: #be5f8b,
  brand-nodejs: #7cbe42,
  red: #fc0303,
  blue: #2a26ff,
  main-color: #0ebcf8,
);

// === z-index === //
$zindexs: (
  dropdown: 1000,
  sticky: 1020,
  fixed: 1030,
  modal-backdrop: 1040,
  modal: 1050,
  popover: 1060,
  tooltip: 1070,
);

// === Typography === //
$font-size-base: 0.875rem !default; // 14px
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;

$h-font-size: (
  h1: $font-size-base * 2.5,
  h2: $font-size-base * 2,
  h3: $font-size-base * 1.75,
  h4: $font-size-base * 1.5,
  h5: $font-size-base * 1.25,
  h6: $font-size-base,
);
