@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100&family=Goldman&family=Kanit&display=swap");
@import "./assets/scss/base/mixins";
@import "./assets/scss/base/variables";

a {
  cursor: pointer;
  color: #fff;
}

input {
  outline: none;
}

html {
  scroll-behavior: smooth;
}

.App {
  width: 100%;
  // height: 100vh;
  overflow: hidden;
  .page-header {
    .img-line-top {
      vertical-align: top;
    }
    background: url(./assets/images/bg_header.png) no-repeat;
    background-size: cover;
    .logo {
      padding-right: 63px;
    }
    .dropdown-list {
      @include respond-above(largest) {
        display: none;
      }
      position: relative;
      &:hover {
        .drop-menu {
          display: block;
        }
      }
      .drop-menu {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 12px;
        z-index: 1;
        a {
          font-family: Goldman;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 155px;
          height: 60px;
          background-size: contain;
          background-image: url(./assets/images/bg_button.svg);
          background-repeat: no-repeat;
          font-size: 12px;
          font-style: normal;
          line-height: 94.8%;
          font-weight: 700;
          color: #fff;
        }
      }
    }
    .header-nav {
      display: flex;
      align-items: center;
      padding-left: 60px;
      padding-right: 60px;
      @include respond-above(largest) {
        padding: 22px 0 142px 62px;
        justify-content: center;
      }
      @include respond-below(largest) {
        justify-content: space-between;
      }
      @include respond-below(small) {
        padding: 12px 24px;
      }
      img {
        padding-right: 63px;
        @include respond-below(medium) {
          padding-right: 0;
        }
      }
      padding-top: 22px;
      padding-bottom: 142px;
      @include respond-below(largest) {
        padding-bottom: 51px;
      }
      @include respond-below(small) {
        padding-bottom: 24px;
      }
      .header-menu {
        display: flex;
        gap: 14px;
        padding-left: 60px;

        @include respond-below(largest) {
          display: none;
        }
        a {
          font-family: Goldman;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 155px;
          height: 60px;
          background-size: contain;
          background-image: url(./assets/images/bg_button.svg);
          background-repeat: no-repeat;
          font-size: 12px;
          font-style: normal;
          line-height: 94.8%;
          font-weight: 700;
          color: #fff;
          &:first-child {
            font-family: Goldman;
            font-size: 26px;
          }
        }
      }
    }
    .header-banner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 38px;
      .btn-app {
        @include respond-below(largest) {
          margin: 0 auto 52px;
        }
      }
      .banner-left {
        padding: 0 78px;
        @include respond-below(largest) {
          padding-right: 0;
          padding: 0 78px;
        }
        @include respond-below(small) {
          padding: 0 24px;
        }
        .banner-title {
          @include respond-below(largest) {
            text-align: center;
          }
          color: #ff9a01;
          font-family: Goldman;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 134.8%;
        }
        .banner-content {
          @include respond-below(largest) {
            text-align: center;
          }
          color: #fff;
          font-family: Goldman;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 186%;
          padding-top: 30px;
          padding-bottom: 48px;
        }
      }
    }
  }

  .page-main {
    .section-about {
      position: relative;
      background: rgba(14, 9, 8, 0.8);
      .container {
        padding: 0 200px;
        @include respond-below(wide) {
          padding: 0 24px;
        }
        .btn-about {
          top: -36px;
          left: 0;
          right: 0;
          position: absolute;
          background-size: contain;
          background-image: url(./assets/images/bg_button.svg);
          background-repeat: no-repeat;
          width: 242.833px;
          height: 94px;
          display: flex;
          align-items: center;
          justify-content: center;
          a {
            color: #fff;
            text-align: center;
            font-family: Goldman;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 94.8%;
          }
        }
        .title {
          padding-top: 34px;
          color: #ff9a01;
          text-align: center;
          font-family: Goldman;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 134.8%;
          letter-spacing: 1.2px;
        }
        .content {
          padding-top: 40px;
          color: #fff;
          text-align: justify;
          font-family: Goldman;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 186.8%;
          letter-spacing: 0.08px;
          @include respond-below(medium) {
            text-align: left;
          }
        }
        .btn-actions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 40px 75px 0;
          @include respond-below(medium) {
            justify-content: center;
            gap: 20px;
          }
        }
        .img-about {
          display: flex;
          justify-content: center;
          padding-top: 26px;
          padding-bottom: 45px;
          img {
            width: 254.581px;
            height: 254.581px;
          }
        }
      }
    }
    .section-xchain-ecosystem {
      .container {
        padding-bottom: 85px;
        @include respond-below(medium) {
          padding-bottom: 48px;
        }
        @include respond-below(small) {
          padding-bottom: 24px;
        }
      }
      .section-wrapper {
        background: #372a67;
        padding: 0 114px;
        @include respond-above(largest) {
          padding: 0 100px;
        }
        @include respond-below(medium) {
          padding: 0 64px;
        }
        @include respond-below(small) {
          padding: 0 24px;
        }
      }
      .section-top {
        display: flex;
        align-items: center;
        flex-direction: column;
        @include respond-above(largest) {
          align-items: flex-end;
        }
        .title {
          padding-top: 52px;
          align-items: flex-end;
          @include respond-above(largest) {
            padding-right: 55px;
          }
          color: #fff;
          text-align: center;
          font-family: Goldman;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 134.8%; /* 40.44px */
          letter-spacing: 1.2px;
          @include respond-above(largest) {
            text-align: center;
          }
        }
        .content {
          padding-top: 10px;
          color: #fff;
          text-align: center;
          font-family: Goldman;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 186.8%; /* 29.888px */
          letter-spacing: 0.08px;
        }
      }
      .container {
        @include respond-above(largest) {
          display: flex;
        }
        .img-xchain {
          margin-top: -32px;
          @include respond-below(wide) {
            margin-top: 0;
          }
          display: flex;
          justify-content: center;
          @include respond-above(largest) {
            padding-right: 170px;
          }
        }
        .list-service {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding: 0 128px 52px;
          gap: 92px 152px;
          @include respond-below(largest) {
            padding: 32px 0;
            gap: 24px 152px;
          }
          @include respond-above(largest) {
            flex-direction: column;
            padding: 0;
          }
          .service {
            width: 330px;
            display: flex;
            .content-wrapper {
              padding-left: 18px;
              .title {
                color: #fff;
                text-align: left;
                font-family: Goldman;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 134.8%; /* 26.96px */
                letter-spacing: 0.8px;
              }
              .content {
                text-align: left;
                color: #fff;
                font-family: Goldman;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 186.8%; /* 18.68px */
                letter-spacing: 1.1px;
              }
            }
          }
        }
      }
    }
    .section-road-map {
      background: rgba(14, 9, 8, 0.8);
      background: url(./assets/images/img-section-roadmap.svg) no-repeat center;
      background-size: cover;
      .container {
        padding-top: 58px;
        padding-bottom: 58px;
        @include respond-below(small) {
          padding-top: 24px;
          padding-bottom: 24px;
        }
        .list-phase {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding: 0 120px 52px;
          @include respond-above(largest) {
            padding: 24px 0;
          }
          @include respond-below(small) {
            padding: 24px 0;
          }
          gap: 80px 24px;
          .phase {
            flex-basis: 446px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
              color: #ff9a01;
              text-align: center;
              font-family: Goldman;
              font-size: 26px;
              font-style: normal;
              font-weight: 700;
              line-height: 134.8%; /* 35.048px */
              letter-spacing: 1.04px;
            }
            .sub-title {
              text-align: center;
              min-height: 91px;
              color: #fff;
              font-family: Goldman;
              font-size: 26px;
              font-style: normal;
              font-weight: 700;
              line-height: 134.8%;
              letter-spacing: 1.04px;
              padding-bottom: 22px;
            }
            .content {
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              width: 446px;
              height: 426px;
              padding-top: 28px;
              background-size: contain;
              background-image: url(./assets/images/img-circle.svg);
              background-repeat: no-repeat;
              @include respond-below(small) {
                width: 340px;
                height: 340px;
              }
              li {
                width: 275px;
                color: #fff;
                font-family: Goldman;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 294.8%; /* 35.376px */
                @include respond-below(small) {
                  width: 200px;
                  line-height: 15px;
                }
              }
            }
          }
        }
      }
    }
    .section-community {
      background: rgba(14, 9, 8, 0.8);
      background: url(./assets/images/img-section-community.png) no-repeat
        center;
      background-size: cover;
      .container {
        padding: 185px 0 139px 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @include respond-below(large) {
          padding: 43px 0 117px 0;
        }
        @include respond-below(medium) {
          padding: 24px;
        }
        .section-left {
          padding-right: 136px;
          @include respond-below(largest) {
            padding-right: 0;
          }
          .btn-app {
            margin: 32px auto;
          }
          .title {
            @include respond-below(largest) {
              text-align: center;
            }
            @include respond-below(wide) {
              padding-top: 48px;
            }
            @include respond-below(small) {
              padding-top: 24px;
            }
            color: #fff;
            font-family: Goldman;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 134.8%; /* 40.44px */
            letter-spacing: 1.2px;
            padding-bottom: 23px;
          }
          .content {
            @include respond-below(largest) {
              text-align: center;
            }
            color: #fff;
            font-family: Goldman;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 186.8%; /* 29.888px */
            letter-spacing: 0.08px;
            padding-bottom: 64px;
            @include respond-below(small) {
              padding-bottom: 24px;
            }
          }
        }
        .section-right {
          @include respond-below(largest) {
            padding-top: 76px;
          }
          @include respond-below(small) {
            padding-top: 24px;
          }
          .media-item {
            position: relative;
            &:not(:last-child) {
              padding-bottom: 37px;
            }
            .img-frame {
              position: relative;
              z-index: 1;
            }
            .icon-media {
              position: absolute;
              top: -45px;
              left: -45px;
              z-index: 2;
              @include respond-below(small) {
                width: 35px;
                height: 35px;
                top: -25px;
                left: -45px;
              }
            }
            .content {
              position: absolute;
              top: 10px;
              left: 34px;
              color: #fff;
              font-family: Goldman;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 186.8%; /* 29.888px */
              letter-spacing: 0.08px;
              padding: 14px 74px;
              @include respond-below(large) {
                max-width: 675.9px;
                height: auto;
                padding: 10px 24px;
                line-height: unset; /* 29.888px */
              }
              @include respond-below(small) {
                padding: 0;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .section-mail {
      padding: 50px 143px;
      background: #372a67;
      @include respond-below(wide) {
        padding: 24px 64px;
      }
      @include respond-below(medium) {
        padding: 12px 24px;
      }
      .container {
        border-radius: 22px;
        border-top: 1px solid #fff;
        border-bottom: 2px solid #fff;
        background: rgba(0, 0, 0, 0.8);
        padding: 33px 0;
        .title {
          color: #ff9a01;
          text-align: center;
          font-family: Goldman;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 134.8%; /* 40.44px */
          letter-spacing: 1.2px;
          padding-bottom: 51px;
        }
        .mail-box {
          margin: 0 59px;
          display: flex;
          position: relative;
          @include respond-below(wide) {
            flex-wrap: wrap;
            justify-content: center;
          }
          @include respond-below(medium) {
            margin: 0 24px;
          }
          img {
            position: absolute;
            left: 20px;
            top: 18px;
          }
          input {
            width: 100%;
            font-size: 16px;
            padding-left: 90px;
            height: 77px;
            margin-right: 41px;
            border-radius: 9px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            @include respond-below(wide) {
              margin-right: 0;
            }
          }
          .btn-send {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 266px;
            padding: 12px;
            border-radius: 9px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            color: #ff9a01;
            text-align: center;
            font-family: Goldman;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 134.8%; /* 40.44px */
            letter-spacing: 1.2px;
            @include respond-below(wide) {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
  .page-footer {
    background: rgba(14, 9, 8, 0.8);
    .container {
      padding: 38px 100px 30px;
      display: flex;
      justify-content: space-between;
      @include respond-below(largest) {
        padding: 38px 120px 76px;
      }
      @include respond-below(large) {
        flex-wrap: wrap;
      }
      @include respond-below(wide) {
        padding: 38px 80px 76px;
      }
      @include respond-below(medium) {
        padding: 38px 40px 76px;
      }
      @include respond-below(small) {
        padding: 24px;
      }
      .content-left {
        .title {
          color: #fff;
          font-family: Goldman;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 186.8%; /* 29.888px */
          letter-spacing: 0.08px;
          padding: 34px 0;
          @include respond-below(large) {
            padding: 14px 0;
          }
        }
        .copyright {
          color: #fff;
          font-family: Goldman;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 186.8%; /* 29.888px */
          letter-spacing: 0.08px;
        }
        @include respond-below(large) {
          padding-bottom: 24px;
        }
      }
      .content-right {
        display: flex;
        gap: 40px;
        @include respond-below(wide) {
          flex-wrap: wrap;
          // justify-content: space-evenly;
        }
        @include respond-below(small) {
          gap: 20px;
        }
        .content-col {
          width: 177px;
        }
        p {
          color: #ff9a01;
          font-family: Goldman;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 134.8%; /* 26.96px */
          letter-spacing: 0.8px;
        }
        ul {
          li {
            a {
              color: #fff;
              font-family: Goldman;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 186.8%; /* 29.888px */
              letter-spacing: 0.08px;
            }
          }
        }
      }
    }
  }
}
.image-container {
  position: relative;
  display: inline-block;
  .centered-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
  }
  .btn-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-image: url(./assets/images/bg_button.svg);
    background-repeat: no-repeat;
    width: 242.833px;
    height: 94px;
    font-family: Goldman;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 94.8%; /* 18.96px */
    a {
      color: #fff;
    }
  }
}

.footer-copyright {
  text-align: center;
  padding: 20px;
  color: #fff;
  font-family: Goldman;
}
